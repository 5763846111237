import { batchActions } from 'util/redux'

import { doOpenSearchPage } from 'actions/pages/doOpenSearchPage'

import * as types from 'constants/action_types'
import { DEFAULT_SORT_ORDER } from 'constants/defaults'

import { selectCurrentMailbox } from 'selectors/mailboxes/selectCurrentMailbox'
import {
  selectCurrentTicketSearchQueryId,
  selectDefaultTicketSearch,
  selectDefaultTicketSearchId,
  selectTicketSearchOperatorValueMap,
  shouldFetchIncompleteSearch,
  selectCurrentCommittedTicketSearchQueryString,
  selectIsFreeFormSearch,
  selectTicketSearchQueryIdByQueryString,
} from 'selectors/search'

import {
  constructGraphQLSearchQueryObject,
  _splitQueryString,
  storeSubmittedQueryIdWithQueryString,
  getSubmittedQueryStringWithMailboxes,
} from 'util/search'
import {
  clearEditorAndAddBaseNode,
  recentSearchQueries,
} from 'components/ConversationList/Header/Search/util'
import {
  selectCurrentSubmittedTicketSearchFilterMatches,
  selectCurrentSubmittedTicketSearchQueryObject,
  selectSearchEditor,
  selectSearchMailboxIds,
  selectTicketSearchQueryObjectByQueryString,
} from 'selectors/search/searchFilters'
import { selectIsOnSearchPage } from 'selectors/location'
import { runOnNextTick } from 'util/functions'
import { selectKnownMailboxes } from 'selectors/mailboxes/selectKnownMailboxes'
import { doLoadSearchTickets } from './doLoadSearchTickets'
import { doFetchTicketSearchByQueryString } from './doFetchTicketSearchByQueryString'
import { doFetchTicketSearch } from './doFetchTicketSearch'

export const CLEAR_SEARCH_TAG = 'clear-search'

export function doClearNonNamedTicketSearchResults({
  keepCurrent = false,
  refetchListOnNextUpdate = false,
} = {}) {
  return (dispatch, getState) => {
    // Elasticsearch refresh interval is set to one second, which means it is
    // possible for ES to index a change but for it to not be available within 1000ms
    // If we query for updated results instantly we might hit old indexed data, which
    // are incorrect. Which is why we defer clearing of old results by 1 second,
    // guaranteeing that we will not hit previous results
    const state = getState()
    setTimeout(() => {
      dispatch({
        type: types.SEARCH_CLEAR_TICKET_RESULTS,
        data: {
          omitNamedSearches: true,
          valueIdMap: selectTicketSearchOperatorValueMap(state),
          queryIdToKeep: keepCurrent
            ? selectCurrentTicketSearchQueryId(state)
            : undefined,
          refetchListOnNextUpdate,
        },
      })
      // GR: Since we no longer use triggers, we have to manually refresh the
      // ticket list search results
      dispatch(doLoadSearchTickets())
    }, 1000)
  }
}

export function doClearTicketSearchResults(options = {}) {
  return {
    type: types.SEARCH_CLEAR_TICKET_RESULTS,
    data: options,
  }
}

export function doUpdateLatestSearch() {
  return (dispatch, getState) => {
    const queryId = selectCurrentTicketSearchQueryId(getState())
    return dispatch({
      type: types.STORE_LATEST_SEARCH,
      data: { queryId },
    })
  }
}

export const doFetchDefaultTicketSearch = () => {
  return (dispatch, getState) => {
    const state = getState()
    const query = selectDefaultTicketSearch(state)
    const queryId = selectDefaultTicketSearchId(state)
    const mailbox = selectCurrentMailbox(state)
    const valueMap = selectTicketSearchOperatorValueMap(state)
    const apiQuery = constructGraphQLSearchQueryObject({ ...query }, valueMap)

    // we need to dispatch SET_CURRENT_TICKET_LIST, because we want to make sure
    // that the default set is set at the currentTicketList. We do it here
    // because we cannot differentiate between a regular search and a default
    // search in the reducer
    dispatch(
      batchActions(
        doFetchTicketSearch(
          queryId,
          query,
          apiQuery,
          undefined,
          undefined,
          undefined,
          { storeLastSearchQueryId: false }
        ),
        {
          type: types.SET_CURRENT_TICKET_LIST,
          data: { query: queryId, mailbox, sortOrder: DEFAULT_SORT_ORDER },
        }
      )
    )
  }
}

// Only search for ticket number if the search query is a number and no other filters are applied
const amendSearchQueryStringIfSearchByNumber = (queryString, state) => {
  const ticketSearchQueryObject = selectTicketSearchQueryObjectByQueryString(
    state,
    queryString
  )

  const searchQueryObjectKeys = Object.keys(ticketSearchQueryObject)

  const { keywords = [] } = ticketSearchQueryObject
  // Ticket number: #123 or 123
  const possibleTicketNumber = keywords[0]?.replace(/^#/, '')
  const isTicketNumberSearch =
    searchQueryObjectKeys.length === 1 &&
    keywords.length === 1 &&
    !isNaN(possibleTicketNumber)
  return isTicketNumberSearch ? possibleTicketNumber : queryString
}

export function doUpdateTicketSearchQuery(queryString, options) {
  const {
    commit = true,
    position,
    reset = false,
    currentPart,
    submit = true,
    shouldIncludeSearchMailboxes,
    isTypedSearch = true,
  } = options
  return (dispatch, getState) => {
    const state = getState()
    const trimmedQueryString = queryString && queryString.trim()
    const searchMailboxIds = selectSearchMailboxIds(state)
    const mailboxes = selectKnownMailboxes(state)
    const isSearchingAllMailboxes = searchMailboxIds.length === mailboxes.length
    const submittedQueryString = submit
      ? getSubmittedQueryStringWithMailboxes({
          searchMailboxIds,
          queryString: trimmedQueryString,
          shouldIncludeSearchMailboxes: shouldIncludeSearchMailboxes
            ? !isSearchingAllMailboxes
            : false,
        })
      : ''

    dispatch({
      type: types.UPDATE_CURRENT_TICKET_SEARCH_QUERY,
      data: {
        commit,
        position,
        queryString: submit ? submittedQueryString : queryString,
        currentPart,
        submit,
        reset,
        isTypedSearch,
      },
    })

    if (!submit) {
      return
    }
    const isOnSearchPage = selectIsOnSearchPage(state)
    if (submittedQueryString) {
      recentSearchQueries.value = submittedQueryString
      const term = amendSearchQueryStringIfSearchByNumber(
        submittedQueryString,
        getState()
      )
      // Replace label values with IDs for search URL
      const searchQueryId = selectTicketSearchQueryIdByQueryString(state, term)
      // we need to store the submitted search query string in session storage to persist it on refresh
      storeSubmittedQueryIdWithQueryString([
        searchQueryId,
        submittedQueryString,
      ])

      if (!isOnSearchPage) {
        // Fix search term isn't showing in the editor when search from other pages:
        // Because we are dismounting the editor when navigating away from subapp pages,
        // we need to wait for the editorState to be updated in the store before we can open the search page
        runOnNextTick(() =>
          dispatch(doOpenSearchPage(searchQueryId, isTypedSearch))
        )
      } else {
        dispatch(doOpenSearchPage(searchQueryId, isTypedSearch))
      }
    } else if (isOnSearchPage) {
      dispatch(
        doOpenSearchPage(selectDefaultTicketSearchId(state), isTypedSearch)
      )
    }
  }
}

export function doToggleListSearchBoxFocused(isFocused) {
  return {
    type: types.TOGGLE_LIST_SEARCH_BOX_STATUS,
    data: { isFocused },
  }
}

export function doMarkSearchAsComplete(queryId) {
  return {
    type: types.MARK_SEARCH_AS_COMPLETE,
    data: { queryId },
  }
}

// This is a version of doFetchTicketSearch which is used
// when you expect a bunch of changes to be happening in short succession
// it is both a performance improvement and race condition protection
let debounceTimeout
export function doLoadSearchTicketsDebounced() {
  clearTimeout(debounceTimeout)
  return (dispatch, getState) => {
    const state = getState()
    const queryId = selectCurrentTicketSearchQueryId(state)

    const callback = () => dispatch(doFetchTicketSearchByQueryString())
    debounceTimeout = setTimeout(callback, 3000)
    dispatch(doMarkSearchAsComplete(queryId))
  }
}

let debounceLoad
export function doLoadIncompleteSearch() {
  clearTimeout(debounceLoad)
  return (dispatch, getState) => {
    const state = getState()
    const should = shouldFetchIncompleteSearch(state)
    if (should) {
      const queryId = selectCurrentTicketSearchQueryId(state)
      dispatch(doMarkSearchAsComplete(queryId))
      debounceLoad = setTimeout(() => {
        dispatch(doFetchTicketSearchByQueryString())
      }, 5000)
    }
  }
}

export const doSubmitTicketsSearch = (
  query,
  { shouldIncludeSearchMailboxes } = {}
) => {
  return (dispatch, getState) => {
    const state = getState()
    const committedSearchQuery = selectCurrentCommittedTicketSearchQueryString(
      state
    )
    const searchQuery = query || committedSearchQuery
    const splitQueryString = _splitQueryString(searchQuery)
    const queryWithoutEmptyFilters = splitQueryString
      ? splitQueryString.filter(part => !part.endsWith(':')).join(' ')
      : ''

    const isFreeFormSearch = selectIsFreeFormSearch(state)
    dispatch(
      doUpdateTicketSearchQuery(queryWithoutEmptyFilters, {
        commit: true,
        reset: !isFreeFormSearch,
        submit: true,
        shouldIncludeSearchMailboxes,
      })
    )
    return selectCurrentSubmittedTicketSearchQueryObject(getState())
  }
}

export const doUpdateSearchMailboxIds = mailboxes => {
  return {
    type: types.SEARCH_MAILBOXES_UPDATE,
    payload: {
      mailboxes,
    },
  }
}

export const doClearSearch = (
  _e,
  { shouldBlur, shouldSubmit = true, onlyClearInput } = {}
) => (dispatch, getState) => {
  const state = getState()
  const searchEditor = selectSearchEditor(state)
  const currentSubmittedTicketSearchFilterMatches = selectCurrentSubmittedTicketSearchFilterMatches(
    state
  )
  const queryString =
    onlyClearInput && shouldSubmit
      ? currentSubmittedTicketSearchFilterMatches.join(' ')
      : ''
  if (!searchEditor) {
    return
  }

  searchEditor.update(clearEditorAndAddBaseNode, {
    tag: CLEAR_SEARCH_TAG,
    onUpdate: () => {
      if (shouldBlur) {
        searchEditor.blur()
      }
    },
  })
  dispatch(
    doUpdateTicketSearchQuery(queryString, {
      commit: true,
      submit: shouldSubmit,
      reset: true,
      isTypedSearch: shouldSubmit,
      shouldIncludeSearchMailboxes: false,
    })
  )
}

export const doUpdateSearchByKey = (key, value) => {
  return {
    type: types.SEARCH_UPDATE_BY_KEY,
    payload: { key, value },
  }
}
