import Bugsnag from '@bugsnag/js'
import { v4 as uuidV4 } from 'uuid'
import config from 'config'
import { doUpdateAccessList, doRemoveAccessList } from 'actions/app'
import {
  doClearNonNamedTicketSearchResults,
  doLoadIncompleteSearch,
} from 'actions/search'
import {
  doRealtimeAiEvent,
  doRealtimeAiStreamingEvent,
} from 'ducks/ai/operations'
import { doRealtimeWalletTransactionEvent } from 'ducks/wallets/operations'
import { doUpdateTickets } from 'actions/tickets'
import { doRealtimeNoteEvent } from 'actions/notes'
import { doRealtimeAccountEvent } from 'actions/account'
import { doFetchMailbox, doRemoveMailboxLocally } from 'actions/mailboxes'
import { doUpdateMailboxLocally } from 'ducks/mailboxes/actions'
import { doFetchAgent, doUpdateAgent, doRemoveAgent } from 'actions/agents'
import {
  doUpdateAgentTicketCollisionStatus,
  doRealtimeSetCurrentAgentCollisionStatus,
  doFetchCollisionStatuses,
  doAgentStartTicketTypingNotification,
} from 'ducks/collisions/actions'
import { startCollisionWindowWatching } from 'ducks/collisions/utils'
import { REFETCH_COLLISIONS_INTERVAL } from 'ducks/collisions/constants'

import * as types from 'constants/action_types'

import { selectAgents } from 'selectors/agents/base'
import { selectMailboxIds } from 'selectors/mailboxes/selectMailboxIds'
import { selectRawTicket } from 'selectors/tickets/byId/selectRawTicket'
import { selectRequestIds } from 'selectors/requests'
import { selectCurrentUser } from 'ducks/currentUser/selectors/selectCurrentUser'
import { selectCurrentTicketSearchQueryId } from 'selectors/search/base'
import metrics from 'util/metrics'
import WindowVisibility from 'util/window_visibility'
import debug, { logError, leaveBreadcrumb } from 'util/debug'
import realtime from 'util/realtime'

import { doRealtimeRoomEvent } from 'ducks/chat/actions/rooms'
import { doTryFetchAccountUsageOnboardingForOnboarding } from 'ducks/accountPreferences/operations'
import { selectFeatureBasedOnboardingWorkflowData } from 'subapps/onboarding/selectors'
import { doMarkFetchingStatus } from './app/doMarkFetchingStatus'

export function doUpdateRealtimeStatus(subscribed) {
  return {
    type: types.UPDATE_REALTIME_STATUS,
    data: {
      subscribed,
    },
  }
}

const actionMap = {
  ticket: { action: doRealtimeTicketEvent },
  changeset: { action: doRealtimeChangesetEvent },
  mailbox: { action: doRealtimeMailboxEvent },
  user: { action: doRealtimeUserEvent },
  settings: { action: doRealtimeSettingsEvent },
  global: { action: doRealtimeGlobalEvent },
  mailbox_access_list: { action: doRealtimeMailboxAccessListEvent },
  comment: { action: doRealtimeNoteEvent },
  agentAction: { action: doUpdateAgentTicketCollisionStatus },
  chat: { action: doRealtimeRoomEvent },
  account: { action: doRealtimeAccountEvent },
  realtime: {
    'ai.suggestion': {
      action: doRealtimeAiEvent,
    },
    'ai.streaming': {
      action: doRealtimeAiStreamingEvent,
    },
    'wallet.transaction': {
      action: doRealtimeWalletTransactionEvent,
    },
  },
}

const MAX_CONNECTION_ERRORS = 5
let connectionErrorCount = 0
let isFocused = true

async function startWatchingChannels(dispatch, channels) {
  try {
    await Promise.all(channels.map(channel => realtime.watch(channel)))
    dispatch(doUpdateRealtimeStatus(true))
    dispatch(doMarkFetchingStatus('subscribeRealtime', false))
  } catch (err) {
    leaveBreadcrumb('startWatchingChannels error', {
      error: err.message,
    })
    throw err
  }
}

// this function should only run once, because it's the socket that
// handles reconnections
export function doSubscribeToRealtime() {
  return async (dispatch, getState) => {
    const state = getState()
    const {
      app: { token },
    } = state
    leaveBreadcrumb('subscribing to realtime')

    try {
      dispatch(doMarkFetchingStatus('subscribeRealtime', true))
      const channels = await realtime.connect(
        token,
        message => watchHandler(message, dispatch),
        () => reconnectHandler('reconnect', dispatch, getState)
      )

      realtime.runIntervalWhenConnected(
        () => reconnectHandler('interval', dispatch, getState),
        REFETCH_COLLISIONS_INTERVAL,
        'reconnectHandler'
      )

      WindowVisibility.addEventListener('focus', () => {
        leaveBreadcrumb('window focus')
        isFocused = true
        reconnectHandler('focus', dispatch, getState)
      })

      WindowVisibility.addEventListener('blur', () => {
        leaveBreadcrumb('window blur')
        isFocused = false
      })
      startCollisionWindowWatching(getState)
      await Promise.all([
        startWatchingChannels(dispatch, channels),
        dispatch(doFetchCollisionStatuses()),
      ])
    } catch (err) {
      leaveBreadcrumb('post-subscribe error', {
        error: err,
      })
      handleConnectionError(err, dispatch)
    }
  }
}

function handleConnectionError(err, dispatch) {
  leaveBreadcrumb('connectionError', {
    error: err.message,
  })
  connectionErrorCount += 1
  // NOTE (jscheel): Make sure we don't get into endless loop of reconnecting.
  if (connectionErrorCount > MAX_CONNECTION_ERRORS) {
    // eslint-disable-next-line no-console
    console.error(`Error connecting to realtime: ${err}`)
    dispatch(doMarkFetchingStatus('subscribeRealtime', true))
  } else {
    setTimeout(() => {
      dispatch(doUpdateRealtimeStatus(false))
      dispatch(doMarkFetchingStatus('subscribeRealtime', false))
      // eslint-disable-next-line no-restricted-properties
    }, Math.round(Math.pow((20 + Math.random()) * connectionErrorCount, 2)))
  }
}

let lastMessageId
function watchHandler(message, dispatch) {
  const { meta } = message
  // if the message id is the same as the previous one, we are in double-subscribe mode
  // if so, skip the message, but log for metrics
  if (message.id && lastMessageId === message.id) {
    // only report for changeset messages
    if (meta && meta.type === 'changeset') {
      if (config.isDevelopment || config.isAlpha) {
        debug('duplicate realtime message', {
          messageId: message.id,
          lastMessageId,
        })
      }
      metrics.increment('realtime_web_changeset_message_duplicated')
    }
    return
  }
  lastMessageId = message.id

  let type
  let subtype

  // skip comment_template, they are unsupported and it's not an error
  if (
    meta &&
    (meta.type === 'comment_template' ||
      meta.type === 'comment_template_category')
  ) {
    return
  }
  if (meta && meta.type && actionMap[meta.type]) {
    type = meta.type

    if (meta.subtype && actionMap[meta.type][meta.subtype]) {
      subtype = meta.subtype
    }
  } else {
    // eslint-disable-next-line no-console
    console.warn('Cannot map realtime message to action', { message })
    Bugsnag.notify(
      new Error('Cannot map realtime message to action'),
      event => {
        // eslint-disable-next-line no-param-reassign
        event.errors[0].errorClass = 'RealtimeActionMapError'
        // eslint-disable-next-line no-param-reassign
        event.errors[0].errorMessage = 'Cannot map realtime message to action'
        event.addMetadata('metaData', {
          meta: {
            ...message.meta,
            actionMapKeys: actionMap ? Object.keys(actionMap) : null,
            attemptedType: meta.type,
          },
        })
      }
    )
    return
  }

  if (subtype) {
    dispatch(actionMap[type][subtype].action(message))
  } else {
    dispatch(actionMap[type].action(message))
  }
}

async function reconnectHandler(reason, dispatch) {
  const realtimeWasConnected = realtime.isConnected()
  const uuid = uuidV4()
  leaveBreadcrumb('reconnectHandler:start', {
    uuid,
    reason,
    isFocused,
    isConnected: realtimeWasConnected,
    state: realtime.getState(),
  })
  if (!isFocused) return
  if (!realtimeWasConnected) return
  try {
    await Promise.all([
      dispatch(
        doFetchCollisionStatuses(uuid, () => {
          leaveBreadcrumb('reconnectHandler:success', {
            uuid,
            reason,
            realtimeWasConnected,
            isFocused,
            realtimeIsConnected: realtime.isConnected(),
            state: realtime.getState(),
          })
        })
      ),
    ])
    dispatch(doRealtimeSetCurrentAgentCollisionStatus())
  } catch (err) {
    const realtimeIsConnected = realtime.isConnected()
    const payload = {
      uuid,
      reason,
      realtimeWasConnected,
      realtimeIsConnected,
      isFocused,
      state: realtime.getState(),
    }
    leaveBreadcrumb('reconnectHandler:error', payload)
    // don't report if it failed because realtime disconnected
    // or because the window is unfocused, these issues are expected
    if (!isFocused || !realtimeIsConnected) return
    logError(err)
  }
}

const findPendingRequest = (optimist = [], changesetId) =>
  optimist.find(
    entry =>
      entry.action &&
      entry.action.optimist &&
      entry.action.optimist.id === changesetId
  )

function doRealtimeTicketEvent(message) {
  return (dispatch, getState) => {
    const {
      meta: { changeset_id: changesetId, request_id: requestId, action },
    } = message
    const state = getState()
    const requestIds = selectRequestIds(state)
    // Skip the realtime update if we made the request
    if (requestIds.includes(requestId)) return false
    // Skip the realtime update if we are expecting an API response
    if (findPendingRequest(state.optimist, changesetId)) return false
    const currentUser = selectCurrentUser(state)
    const ticket = realtime.buildTicketFromMessage(message, currentUser)

    switch (action) {
      case 'destroy':
        dispatch({
          type: types.DELETE_TICKETS_SUCCESS,
          data: {
            tickets: [ticket],
          },
          meta: { requestId },
        })
        break
      default:
      // do nothing
    }

    return true
  }
}

const bufferFlushDelay = 2000
const bufferMaxSize = 25
let bufferFlushInterval = null
let bufferedTickets = []

function flushBuffer(dispatch, options) {
  const tickets = bufferedTickets
  bufferedTickets = []
  dispatch(doUpdateTickets(tickets, options))
  dispatch(doLoadIncompleteSearch())
}

function bufferedDispatch(dispatch, ticket, options) {
  bufferedTickets.push(ticket)
  // if the buffer hits upper bound, flush it
  if (bufferedTickets.length >= bufferMaxSize) {
    flushBuffer(dispatch, options)
    return
  }
  if (bufferFlushInterval) clearInterval(bufferFlushInterval)
  bufferFlushInterval = setTimeout(() => {
    flushBuffer(dispatch, options)
  }, bufferFlushDelay)
}

let lastSeenChangesetId
function doRealtimeChangesetEvent(message) {
  return (dispatch, getState) => {
    const {
      meta: { timestamp, changeset_id: changesetId, request_id: requestId },
    } = message
    const { lastChangesetId } = message
    metrics.increment('realtime_web_changeset_message_received')
    if (config.isDevelopment || config.isAlpha) {
      debug('realtime changeset message', {
        changesetId,
        lastChangesetId,
        lastSeenChangesetId,
      })
    }

    if (
      lastChangesetId &&
      lastSeenChangesetId &&
      lastChangesetId !== lastSeenChangesetId
    ) {
      if (config.isDevelopment || config.isAlpha) {
        // eslint-disable-next-line no-console
        console.error('lastChangesetId mismatch', {
          changesetId,
          lastChangesetId,
          lastSeenChangesetId,
        })
      }
      metrics.increment('realtime_web_changeset_message_mismatch')
    }
    lastSeenChangesetId = changesetId

    const state = getState()
    const requestIds = selectRequestIds(state)

    // Skip the realtime update if we made the request
    if (requestIds.includes(requestId)) {
      debug('we made the request, ignoring')
      return false
    }
    // Skip the realtime update if we are expecting an API response
    if (findPendingRequest(state.optimist, changesetId)) {
      debug("we're expecing API response, ignoring")
      return false
    }
    const actions = []
    actions.push(
      doClearNonNamedTicketSearchResults({
        keepCurrent: true,
        refetchListOnNextUpdate: true,
      })
    )

    const currentUser = selectCurrentUser(state)
    const ticket = realtime.buildTicketFromMessage(message, currentUser)
    const ticketInStore = selectRawTicket(state, ticket.id)
    if (
      ticket &&
      ticketInStore &&
      ticketInStore.system_updated_at >= ticket.system_updated_at
    ) {
      if (config.isDevelopment || config.isAlpha) {
        debug(`Realtime update for ${ticket.id} is stale, dropping`, {
          inStore: ticketInStore.system_updated_at,
          inMessage: ticket.system_updated_at,
        })
      }
      return false
    }
    ticket.timestamp = timestamp.toString()

    if (ticket.diff) {
      bufferedDispatch(dispatch, ticket, {
        currentQueryId: selectCurrentTicketSearchQueryId(state),
      })
    }

    return true
  }
}

// We were using mailbox.active from realtime mailbox to check whether the mailbox is activated
// but the mailbox's active status is showing by state now. Check both of them in case it's changed on backend again:
const isMailboxActiveOrSyncing = mailbox =>
  mailbox.active || ['active', 'syncing'].includes(mailbox.state)

function doRealtimeMailboxEvent(message) {
  return (dispatch, getState) => {
    const {
      data: mailbox,
      meta: { action },
    } = message
    if (!mailbox.id) {
      // TODO (jscheel): Determine if we want to report this bugsnag.
      return
    }
    const state = getState()
    const onboardingWorkflowData = selectFeatureBasedOnboardingWorkflowData(
      state
    )
    switch (action) {
      case 'create':
        dispatch(doFetchMailbox(mailbox.id))
        dispatch(
          doTryFetchAccountUsageOnboardingForOnboarding(
            onboardingWorkflowData.mailbox?.usageKey,
            {
              completed: isMailboxActiveOrSyncing(mailbox),
              completedEventName: 'onboarding connected mailbox',
              shouldSetFlag: true,
            }
          )
        )
        break
      case 'update':
        if (selectMailboxIds(state).indexOf(mailbox.id) === -1) {
          dispatch(doFetchMailbox(mailbox.id))
        } else {
          // The user_ids from meta is undefined (even after updating access) and we need to consider group_ids too,
          // and remove mailbox after updating access will remove it from channel entities too.
          // So we will update accessible or inaccessible mailboxes in UPDATE_MAILBOX_SUCCESS reducer instead.
          dispatch(
            doUpdateMailboxLocally(mailbox, {
              shouldRebuildMenu: true,
            })
          )
        }
        // The first mailbox is converted from demo
        dispatch(
          doTryFetchAccountUsageOnboardingForOnboarding(
            onboardingWorkflowData.mailbox?.usageKey,
            {
              completed: isMailboxActiveOrSyncing(mailbox),
              completedEventName: 'onboarding connected mailbox',
              shouldSetFlag: true,
            }
          )
        )
        break
      case 'destroy':
        dispatch(doRemoveMailboxLocally(mailbox.id))
        break
      default:
        // eslint-disable-next-line no-console
        console.warn('Unknown message action')
        break
    }
  }
}

function doRealtimeMailboxAccessListEvent(message) {
  return dispatch => {
    const {
      data: accessList,
      meta: { action, mailbox_id: mailboxId },
    } = message
    switch (action) {
      case 'create':
      case 'update':
        // NOTE (jscheel): Access lists are so basic that we don't need to fetch
        // them separately right now. For now, the reducer will simply create
        // a new access list if one does not exist yet.
        dispatch(
          doUpdateAccessList({
            mailboxId,
            agentIds: accessList.agent_ids,
          })
        )
        break
      case 'destroy':
        // NOTE (jscheel): Access lists do not have their own id right now, so
        // we search for them via their mailbox id.
        dispatch(doRemoveAccessList(mailboxId))
        break
      default:
        // eslint-disable-next-line no-console
        console.warn('Unknown message action')
        break
    }
  }
}

const agentIsMissing = (state, user) => {
  return (
    selectAgents(state)
      .map(agent => agent.id)
      .indexOf(user.id) === -1
  )
}

function doRealtimeUserEvent(message) {
  return (dispatch, getState) => {
    const {
      data: user,
      meta: { action, role },
    } = message
    if (!user.id) return
    const state = getState()
    switch (action) {
      case 'create':
        if (role === 'agent') {
          dispatch(doFetchAgent(user.id))
        }
        break
      case 'update':
        // NOTE (jscheel): Right now we only deal with agent events.
        if (role === 'agent') {
          if (user.archived) {
            dispatch(doRemoveAgent(user.id))
          } else if (agentIsMissing(state, user)) {
            dispatch(doFetchAgent(user.id))
          } else {
            dispatch(doUpdateAgent(user))
          }
        }
        break
      case 'destroy':
        dispatch(doRemoveAgent(user.id))
        break
      default:
        // eslint-disable-next-line no-console
        console.warn('Unknown message action', message)
        break
    }
  }
}

function doRealtimeSettingsEvent(message) {
  return () => {
    // eslint-disable-next-line no-console
    console.log(message)
  }
}

function doRealtimeGlobalEvent(message) {
  return () => {
    // eslint-disable-next-line no-console
    console.log(message)
  }
}

// This method gets imported in a crap load of places. Leaving it here for now for backward
// compatibility
export const doRealtimeAgentStartTicketTypingNotification = doAgentStartTicketTypingNotification
